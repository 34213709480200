<template>
  <Layout>
    <section class="main-section">
      <div class="text-content">
        <h1>SERWISUJ POJAZD</h1>
        <p>RAZEM Z NAMI, SZYBKO I WYGODNIE W JEDNEJ APLIKACJI!</p>

        <div class="button-items download-app-buttons">
          <div class="btn app-store">
            <img src="@/assets/img/appstore.png" alt="App Store">
          </div>

          <div class="btn google-play">
            <img src="@/assets/img/google-play.png" alt="Google Play">
          </div>
        </div>
      </div>

      <div class="image-content">
        <img src="@/assets/img/phone.png" alt="Phone">
      </div>
    </section>

    <section class="header-section">
      <h2><span class="pojazd-text-primary">Co znajdziesz</span> w aplikacji POJAZD24?</h2>
      <ul class="features-list">
        <li>
          <div class="pojazd-checkmark fa fa-check"></div>
          <span>Umów wizytę i wybierz opis zgłoszenia naprawy</span>
        </li>
        <li>
          <div class="pojazd-checkmark fa fa-check"></div>
          <span>Śledź drogę aktualnej naprawy i historię ostatnich napraw</span>
        </li>
        <li>
          <div class="pojazd-checkmark fa fa-check"></div>
          <span>Wyświetlisz aktualne promocje w warsztatach w Twojej okolicy</span>
        </li>
      </ul>
    </section>

    <div class="image-container">
      <img src="@/assets/img/apps.png" alt="Apps">
    </div>

    <section class="header-section">
      <h2><span class="pojazd-text-primary">Jak zacząć korzystać</span> z aplikacji POJAZD24?</h2>
    </section>

    <section class="steps-section">
      <div class="step">
        <div class="step-content">
          <div class="step-number">01</div>
          <div class="step-text">
            <h3>Zarejestruj się</h3>
            <p>Rejestracja konta w Pojazd24 umożliwi Ci korzystanie w pełni z aplikacji.</p>
          </div>
        </div>
      </div>
      <div class="step">
        <div class="step-content reverse">
          <div class="step-text">
            <h3>Dodaj samochód</h3>
            <p>Udaj się do jednego z naszych warsztatów w celu dodania samochodu do bazy danych.</p>
          </div>
          <div class="step-number">02</div>
        </div>
      </div>
      <div class="step">
        <div class="step-content">
          <div class="step-number">03</div>
          <div class="step-text">
            <h3>Twórz historię samochodu</h3>
            <p>Wszystkie Twoje wizyty w warsztacie będą zapisane w aplikacji wraz z wykonywanymi czynnościami.</p>
          </div>
        </div>
      </div>
      <div class="step">
        <div class="step-content reverse">
          <div class="step-text">
            <h3>Gotowe!</h3>
            <p>Teraz możesz sprawdzać status i historię napraw samochodu oraz będziesz informowany na bieżąco o promocjach z Twojego serwisu i okolicznych warsztatów.</p>
          </div>
          <div class="step-number disable-stroke">04</div>
        </div>
      </div>
    </section>
  </Layout>
</template>

<script>
import Layout from "@/components/Layout.vue";

export default {
  name: 'HomeView',
  components: {
    Layout
  }
}
</script>