<template>
  <Layout>
    <section class="main-section">
      <div class="text-content">
        <p>CENNIK</p>
      </div>
    </section>

    <!-- todo -->

    <section class="header-section">
      <h2><span class="pojazd-text-primary">Co znajdziesz</span> w aplikacji POJAZD24?</h2>
      <ul class="features-list">
        <li>
          <div class="pojazd-checkmark fa fa-check"></div>
          <span>Umów wizytę i wybierz opis zgłoszenia naprawy</span>
        </li>
        <li>
          <div class="pojazd-checkmark fa fa-check"></div>
          <span>Śledź drogę aktualnej naprawy i historię ostatnich napraw</span>
        </li>
        <li>
          <div class="pojazd-checkmark fa fa-check"></div>
          <span>Wyświetlisz aktualne promocje w warsztatach w Twojej okolicy</span>
        </li>
      </ul>
    </section>

    <div class="image-container">
      <img src="@/assets/img/apps.png" alt="Apps">
    </div>
  </Layout>
</template>

<script>
import Layout from "@/components/Layout.vue";

export default {
  name: 'HomeView',
  components: {
    Layout
  }
}
</script>

<style scoped>
.main-section {
  text-align: left;
}

.text-content p {
  font-size: 1.2em;
}
</style>