<template>
  <div class="wrapper">
    <div class="main">
      <Navbar />

      <div class="content">
        <slot />
      </div>

      <Footer />
      <!-- footer -->
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'

export default {
  components: { Navbar, Footer }
}
</script>