import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import AboutUsView from "@/views/AboutUsView.vue";
import HowToDownloadView from "@/views/HowToDownloadView.vue";
import PricesView from "@/views/PricesView.vue";
import RulesView from "@/views/RulesView.vue";
import PrivacyPolicyView from "@/views/PrivacyPolicyView.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: AboutUsView
  },
  {
    path: '/how-to-download',
    name: 'how-to-download',
    component: HowToDownloadView
  },
  {
    path: '/prices',
    name: 'prices',
    component: PricesView
  },
  {
    path: '/rules',
    name: 'rules',
    component: RulesView
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicyView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router