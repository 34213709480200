<template>
  <section class="download-app">
    <div class="download-app-content">
      <h1><span class="pojazd-text-primary">Pobierz aplikację</span> już teraz</h1>
      <p>
        Serwisuj samochód szybko i wygodnie umawiaj wizyty
        w warsztacie z poziomu komórki! Bądź na bieżąco podczas
        pobytu samochodu w warsztacie! Korzystaj z promocji
        z okolicznych serwisów!
      </p>

      <div class="button-items">
        <div class="btn app-store">
          <img src="@/assets/img/appstore.png" alt="App Store">
        </div>

        <div class="btn google-play">
          <img src="@/assets/img/google-play.png" alt="Google Play">
        </div>
      </div>
    </div>
  </section>

  <footer>
    <div class="footer-content">
      <div class="contact-info">
        <p><i class="fa fa-phone"></i> 510 622 563</p>
        <p><i class="fa fa-envelope"></i> kontakt@pojazd24.pl</p>
        <div class="social-media">
          <a href="#"><i class="fa fa-facebook-square"></i></a>
          <a href="#"><i class="fa fa-whatsapp"></i></a>
          <a href="#"><i class="fa fa-instagram"></i></a>
        </div>
      </div>
      <div class="content">
        <h3>Przyszłość jest już blisko z Pojazd24!</h3>
        <p>Serwisuj samochód szybko i wygodnie, umawiaj wizyty w warsztacie z poziomu komórki! Bądź na bieżąco z naprawami i korzystaj z najlepszych ofert serwisowych w Twojej okolicy dzięki Pojazd24.</p>
      </div>
    </div>
    <div class="footer-bottom">
      <p>&copy; {{ currentYear }} | Network24 | <RouterLink :to="{ name: 'privacy-policy' }">Polityka prywatności</RouterLink> | <RouterLink :to="{ name: 'rules' }">Regulamin</RouterLink></p>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear()
    };
  }
}
</script>

<style scoped>
footer {
  background-color: #4a4a4a;
  color: #ffffff;
  padding: 20px 0;
  font-family: "Comfortaa", sans-serif;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  flex-wrap: wrap;
}

.contact-info, .content {
  flex: 1;
  margin: 0 20px;
  min-width: 300px;
}

.contact-info {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

.contact-info p {
  margin: 10px 0;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.contact-info p i {
  margin-right: 24px;
  font-size: 32px;
  width: 24px;
}

.social-media {
  display: flex;
  align-items: center;
  margin-top: 24px;
  gap: 24px;
}

.social-media a i {
  font-size: 46px;
}

.social-media a {
  color: #ffffff;
  text-decoration: none;
  font-size: 30px;
}

.social-media a:hover {
  color: #d4d4d4;
}

.content {
  text-align: right;
}

.content h3, .content p {
  margin: 10px 0;
  color: #ffffff;
}

.footer-bottom {
  text-align: center;
}

.footer-bottom p {
  color: #ffffff;
}

.footer-bottom a {
  color: #ffffff;
  text-decoration: none;
}

.footer-bottom a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    padding: 0 20px;
  }

  .contact-info, .content {
    margin: 10px 0;
    text-align: center;
  }

  .contact-info {
    padding-left: 0;
  }

  .social-media {
    justify-content: center;
  }

  .social-media a {
    font-size: 24px;
  }
}
</style>