<template>
  <div class="navbar">
    <img src="@/assets/img/logo.png" alt="Logo" class="mb-4 logo">

    <div class="menu-toggle" @click="toggleMenu">
      <i class="menu-icon fa fa-bars"></i>
    </div>

    <ul :class="{ 'menu-active': isMenuActive }">
      <li v-for="(item, index) in items" :class="item.customCss" :key="index">
        <RouterLink :active-class="item.to ? 'active' : ''" :to="{ name: item.to }">
          {{ item.name }}
        </RouterLink>
      </li>

      <li class="login-button">
        <a href="//dashboard.pojazd24.pl">
          LOGOWANIE
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { name: "STRONA GŁÓWNA", to: "home" },
        { name: "O NAS", to: "about-us" },
        { name: "CENNIK", to: "prices" }, // TODO
        { name: "JAK POBRAĆ", to: "how-to-download" },
        { name: "REGULAMIN", to: "rules" } // TODO
      ],
      isMenuActive: false
    }
  },

  methods: {
    toggleMenu() {
      this.isMenuActive = !this.isMenuActive;
    },
    updateSidebarLinks() {
      const activeRouterLink = document.getElementsByClassName("active router-link-exact-active");

      if (activeRouterLink) {
        for (const link of activeRouterLink) {
          let parent = link.parentElement;
          while (parent && parent.tagName === "LI") {
            if (parent) {
              parent.classList.add("active");
            }

            parent = parent.parentElement;
          }
        }
      }
    }
  },

  mounted() {
    this.updateSidebarLinks();
  }
}
</script>
<style scoped>
.navbar {
  top: 0;
  width: 85%;
  margin: auto;
  padding: 35px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  width: 308px;
  cursor: pointer;
}

.navbar ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
}

.navbar ul li {
  margin: 0 20px;
  font-weight: 400;
  font-size: 20px;
  display: flex;
  align-items: center;
}

.navbar ul li a {
  text-decoration: none;
  color: #50555C;
  text-transform: uppercase;
}

.active a {
  color: #3DB8BD !important;
}

.login-button {
  background-color: #3DB8BD;
  border-radius: 5px;
  border: 8px solid #3DB8BD;
  display: flex;
  align-items: center;
}

.login-button a {
  color: white !important;
}

.menu-toggle {
  display: none;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    width: 100%;
    padding: 20px;
    position: relative;
  }

  .logo {
    width: 200px;
  }

  .navbar ul {
    display: none;
    flex-direction: column;
    width: 100%;
    padding: 0;
    background-color: #fff;
    position: absolute;
    top: 100%;
    left: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
    align-items: center;
  }

  .navbar ul.menu-active {
    display: flex;
  }

  .navbar ul li {
    margin: 10px 0;
    text-align: center;
    font-size: 18px;
    display: flex;
    align-items: center;
  }

  .navbar ul li a {
    font-size: 18px;
  }

  .menu-toggle {
    display: block;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    position: relative;
    z-index: 2;
  }

  .menu-icon {
    font-size: 32px;
  }
}
</style>